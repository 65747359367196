// Imports
import React from 'react';
import Content from '../content';
import styled, { css } from 'styled-components/macro';


// Styled components
const HeadingContainer = styled.div`
	display: flex;
	width: 100%;
	padding: 3.75rem 0rem;
	flex-direction: column;
	gap: 1.25rem;
	
	align-items: center;
	
	background-color: hsl(206, 60%, 94%);
	box-shadow: 0 0 0 100vmax hsl(206, 60%, 94%);
	clip-path: inset(-100vmax -100vmax 0 -100vmax);
	
	text-align: center;
`;

const Heading = styled.h1`
	color: hsl(206, 69%, 35%);
	font-family: 'Zilla Slab';
	font-size: 3.75rem;
	font-weight: 600;
	line-height: 1;
`;

interface DescriptorProps {
	maxWidth?: string;
}

const Descriptor = styled.p<DescriptorProps>`
	&&& {
		color: hsl(206, 25%, 20%);
		font-size: 1.5rem;
		line-height: 1.2;
		
		${({ maxWidth }) =>
			maxWidth
				? css`
						max-width: ${maxWidth};
					`
				: ''}
	}
`;

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	
	width: 100%;
`;

const Container = styled.div`
	max-width: 68.75rem;
	width: 100%;
	line-height: 1.2;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	
	p {
		a {
			color: inherit;
			text-decoration: underline;
			cursor: pointer;
			
			&:hover {
				text-decoration: none;
			}
		}
		
		b {
			font-weight: 600;
		}
	}
	
	p + ul {
		margin-block-start: -0.25rem;
		padding-inline-start: 2.5rem;
	}
	
	p:not(div[class^='field-error-message'] p),
	p + ul {
		font-size: 1.25rem;
		line-height: 1.2;
		
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}
`;


// Define the accepted props
interface Props extends React.PropsWithChildren {
	heading: string;
	descriptor: string | { text: string; textMaxWidth?: string };
}


// Function component
const SimplePageTemplate: React.FC<Props> = ({ heading, descriptor, children }) => (
	<Content>
		<Wrapper>
			<Container>
				<HeadingContainer id='top'>
					<Heading>{heading}</Heading>
					
					<Descriptor maxWidth={typeof descriptor === 'string' ? undefined : descriptor.textMaxWidth}>
						{typeof descriptor === 'string' ? descriptor : descriptor.text}
					</Descriptor>
				</HeadingContainer>
				
				{children}
			</Container>
		</Wrapper>
	</Content>
);

export default SimplePageTemplate;
