// Imports
import styled, { css } from 'styled-components/macro';
import { BannerListItem } from '.';


// Styled components
const Wrapper = styled.li<{ ordered?: boolean }>`
	max-width: 21.25rem;
	padding: 1.6rem 1.2rem;
	
	width: 100%;
	
	border-radius: 0.3125rem;
	border: 2px solid hsl(36, 56%, 69%);
	background-color: hsl(0, 0%, 100%);
	
	${({ ordered }) =>
		ordered
			? css`
					counter-increment: step-counter;
					list-style-position: inside;
					
					&::before {
						all: unset;
						content: counter(step-counter);
						color: hsl(206, 45%, 64%);
						font-size: 3.75rem;
						font-weight: 500;
						line-height: 1;
					}
				`
			: ''}
`;

const Prefix = styled.span`
	color: hsl(206, 45%, 64%);
	font-size: 3.75rem;
	font-weight: 500;
	line-height: 1;
`;

const Label = styled.strong`
	display: block;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1;
	color: hsl(206, 83%, 27%);
	padding-left: 0.1rem;
	margin-top: 0.31rem;
`;

const Description = styled.p`
	&&& {
		color: hsl(206, 25%, 20%);
		font-size: 1.125rem;
		line-height: 1.375rem;
		padding-left: 0.1rem;
		margin-top: 0.9375rem;
	}
`;


// Define the accepted props
interface Props extends BannerListItem {
	ordered?: boolean;
}


// Function component
const Item: React.FC<Props> = ({ prefix, label, description, ordered }) => (
	<Wrapper ordered={ordered}>
		{prefix && <Prefix>{prefix}</Prefix>}
		<Label>{label}</Label>
		<Description>{description}</Description>
	</Wrapper>
);

export default Item;
