// Imports
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';


// Styled components
interface WrapperProps {
	isLoading: boolean;
}

const Wrapper = styled.img<WrapperProps>`
	${({ width, height }) => css`
		aspect-ratio: auto ${width ?? 0} / ${height ?? 1};
	`}
	
	${({ isLoading }) =>
		isLoading
			? css`
					background-color: hsl(0, 0%, 87%);
				`
			: ''}
`;


// Define the accepted props
type Props = Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'ref'>;

const LoadingImage: React.FC<Props> = ({ src: srcProp, ...props }) => {
	// Use state
	const [src, setSrc] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	
	
	// Handle loading image, accounting for known issues with hydration
	//  -> https://github.com/facebook/react/issues/15446
	const reactSnap = navigator.userAgent === 'ReactSnap';
	
	useEffect(() => {
		if (!isLoading || reactSnap) {
			return;
		}
		
		const img = new Image();
		
		img.src = srcProp ?? '';
		img.onload = () => setSrc(srcProp ?? '');
	}, [isLoading, srcProp, reactSnap]);
	
	
	// Return JSX
	return (
		<Wrapper
			{...props}
			isLoading={isLoading}
			onLoad={() => setIsLoading(false)}
			src={src ?? undefined}
			as={src ? 'img' : 'div'}
		/>
	);
};

export default LoadingImage;
