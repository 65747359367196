// Imports
import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { styling } from './link';


// Styled component
interface ContainerProps {
	isOpen: boolean;
}

const Container = styled.div<ContainerProps>`
	display: inline-block;
	position: relative;
`;

interface MenuProps {
	isOpen: boolean;
	columns: number;
	numberOfItems: number;
}

export const Menu = styled.div<MenuProps>`
	display: grid;
	min-width: 15.625rem;
	
	grid-template-columns: repeat(auto-fill, 15.625rem);
	
	transition:
		opacity 0.2s ease-in-out,
		transform 0.2s ease-in-out;
	position: absolute;
	top: calc(50% + 1.83rem);
	left: calc(-2rem * ${({ columns }) => columns});
	z-index: 20;
	border-radius: 0.625rem;
	box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
	background-color: #fff;
	padding: 0.9375rem;
	column-gap: 2.5rem;
	row-gap: 1.25rem;
	max-width: ${({ columns }) => `calc(15.625rem * ${columns} + 2.5rem * ${columns - 1} + 2.5rem)`};
	
	@media (max-width: 70rem) {
		all: unset;
		grid-template-columns: repeat(auto-fill, 15.625rem);
		
		display: grid;
		min-width: 15.625rem;
		max-width: 15.625rem;
		row-gap: 0.625rem;
		overflow: hidden;
	}
	
	${({ isOpen, numberOfItems }) =>
		isOpen
			? css`
					transform: none;
					opacity: 1;
					pointer-events: auto;
					
					@media (max-width: 70rem) {
						transition: height 0.28s ease-in ${(0.28 / numberOfItems).toFixed(3)}s;
						height: calc(2.5rem * ${numberOfItems} + 0.625rem * ${numberOfItems});
					}
				`
			: css`
					transform: scale(0.98) translateY(0.25rem);
					opacity: 0;
					pointer-events: none;
					
					@media (max-width: 70rem) {
						transition: height 0.28s ease-out;
						transform: none;
						height: 0;
						opacity: 1;
					}
				`}
`;

export const Chevron = styled.span`
	font-size: 0.625rem;
	will-change: transform;
	transition: transform 0.2s ease-in-out;
`;

const MenuButton = styled.button<Pick<MenuProps, 'isOpen' | 'numberOfItems'>>`
	${styling}
	
	border: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
	appearance: none;
	cursor: pointer;
	
	display: flex;
	gap: 0.5rem;
	align-items: center;
	
	${({ isOpen }) =>
		isOpen
			? css`
					&::after {
						opacity: 1;
						transform: scaleX(1);
					}
					
					> ${Chevron} {
						transform: rotate(-180deg);
					}
				`
			: ''}
`;

export const wrapperBackgroundColors = ['hsl(96, 30%, 95%)', 'hsl(206, 30%, 95%)', 'hsl(36, 30%, 95%)'];
const iconBackgroundColors = ['hsl(96, 54%, 59%)', 'hsl(206, 69%, 43%)', 'hsl(36, 56%, 69%)'];
const fontColors = ['hsl(96, 83%, 27%)', 'hsl(206, 83%, 27%)', 'hsl(36, 100%, 30%)'];

interface MenuItemIconProps {
	backgroundColor: string;
	icon: IconDefinition;
}

const MenuItemIconContainer = styled.div`
	font-size: 1.125rem;
	grid-area: 1 / 1 / 3 / 2;
	display: flex;
	align-items: center;
`;

export const MenuItemIcon = styled.div<MenuItemIconProps>`
	width: 2.5rem;
	height: 2.5rem;
	background-color: ${({ backgroundColor }) => backgroundColor};
	color: #fff;
	display: flex;
	border-radius: 0.3125rem;
	align-items: center;
	justify-content: center;
	
	@media (max-width: 70rem) {
		width: 2.5rem;
		height: 2.5rem;
	}
`;

interface MenuItemTitleProps {
	fontColor: string;
	hideArrow: boolean;
}

export const MenuItemTitle = styled.figcaption<MenuItemTitleProps>`
	all: unset;
	position: relative;
	font-family: inherit;
	font-size: 1.125rem;
	font-weight: 500;
	width: fit-content;
	line-height: 1;
	text-decoration: none;
	color: ${({ fontColor }) => fontColor};
	grid-area: 1 / 2 / 2 / 3;
	
	@media (max-width: 70rem) {
		grid-area: 1 / 2 / 2 / 3;
		display: flex;
		align-items: center;
	}
	
	&::after {
		content: '→';
		position: absolute;
		left: 100%;
		color: inherit;
		font-weight: 400;
		opacity: 0;
		
		transition:
			opacity 0.15s ease-in-out,
			transform 0.15s ease-in-out;
		
		${({ hideArrow }) =>
			hideArrow
				? css`
						display: none;
					`
				: ''}
	}
`;

interface MenuItemProps {
	backgroundColorOnHover: string;
	enableHoverEffects?: boolean;
}

export const MenuItem = styled.figure<MenuItemProps>`
	all: unset;
	display: grid;
	grid-template-columns: 2.5rem 12.5rem;
	column-gap: 0.625rem;
	grid-template-rows: 1.125rem 2.25rem;
	row-gap: 0.31rem;
	border-radius: 0.3125rem;
	
	@media (max-width: 70rem) {
		grid-template-columns: 2.5rem auto;
		grid-template-rows: 2.5rem;
		row-gap: 0;
	}
	
	${({ enableHoverEffects, backgroundColorOnHover }) =>
		enableHoverEffects
			? css`
					&:hover,
					&[data-hover] {
						box-shadow: 0 0 0 0.5rem ${backgroundColorOnHover};
						background-color: ${backgroundColorOnHover};
						
						${MenuItemTitle}::after {
							opacity: 1;
							transform: translateX(0.325rem);
						}
					}
				`
			: ''}
`;

const MenuItemLink = styled.a`
	color: inherit;
	text-decoration: none;
	
	@media (max-width: 70rem) {
		width: fit-content;
		
		&:first-child {
			margin-top: 0.625rem;
		}
	}
`;

const MenuItemDescription = styled.p`
	display: block;
	margin: 0;
	font-size: 0.875rem;
	line-height: 1.125rem;
	grid-area: 2 / 2 / 3 / 3;
	
	@media (max-width: 70rem) {
		display: none;
		grid-area: none;
	}
`;


// Define what navigation items look like
interface BaseItem extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
	title: string;
	icon: IconDefinition;
	description?: string;
}

interface AnchorItem extends BaseItem {
	href: string;
	openOnSamePage?: boolean;
}

interface NavLinkItem extends BaseItem {
	to: string;
}


// Define the accepted props
interface Props {
	name: string;
	text: string;
	items: (NavLinkItem | AnchorItem)[];
	activeDropdown: string | null;
	setActiveDropdown: React.Dispatch<React.SetStateAction<string | null>>;
	columns: number;
	enableMenuItemHoverEffects?: boolean;
	hideArrowOnMenuItemHover?: boolean;
	className?: string;
	storybookHover?: true;
}


// Function component
const Dropdown: React.FC<Props> = ({
	name,
	text,
	items,
	activeDropdown,
	setActiveDropdown,
	columns,
	enableMenuItemHoverEffects,
	hideArrowOnMenuItemHover,
	className,
	storybookHover,
}) => {
	// Use state
	const [isOpen, setIsOpen] = useState(name === activeDropdown);
	
	
	// Use ref
	const menuButton = useRef<HTMLButtonElement>(null);
	
	
	// Decide if the menu should be open
	const wasPreviouslyOpen = useRef(false);
	
	useEffect(() => {
		let shouldBeOpen = name === activeDropdown;
		
		if (!shouldBeOpen && wasPreviouslyOpen.current) {
			if (window.matchMedia('(max-width: 70rem)').matches && activeDropdown?.startsWith('mobile-navigation')) {
				shouldBeOpen = true;
			}
		}
		
		wasPreviouslyOpen.current = shouldBeOpen;
		setIsOpen(shouldBeOpen);
	}, [activeDropdown, name]);
	
	
	// Handle key down
	const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
		if (e.key === 'Escape') {
			e.stopPropagation();
			
			setActiveDropdown(activeDropdown?.startsWith('mobile-navigation') ? 'mobile-navigation' : null);
			menuButton.current?.focus();
		}
	};
	
	
	// Handle click
	const handleClick = () => {
		if (activeDropdown?.startsWith('mobile-navigation')) {
			if (activeDropdown === name) {
				wasPreviouslyOpen.current = false;
				setActiveDropdown('mobile-navigation');
				
				return;
			}
			
			if (isOpen) {
				wasPreviouslyOpen.current = false;
				setIsOpen(false);
				
				return;
			}
			
			setActiveDropdown(name);
		}
		
		setActiveDropdown(activeDropdown === name ? null : name);
	};
	
	
	// Prevent some problematic usages
	if (items.some(({ icon }) => icon.prefix !== 'fad')) {
		throw new Error('A menu item’s icon should come from the "duotone" package');
	}
	
	
	// Return JSX
	return (
		<Container id={`navigation-dropdown-${name}`} isOpen={isOpen} className={className}>
			<MenuButton
				type='button'
				aria-controls={`${name}-menu`}
				aria-expanded={isOpen}
				isOpen={isOpen}
				onClick={handleClick}
				ref={menuButton}
				data-hover={storybookHover}
				numberOfItems={items.length}
			>
				<span>{text}</span>
				<Chevron>
					<FontAwesomeIcon icon={faChevronDown} />
				</Chevron>
			</MenuButton>
			
			<Menu
				id={`${name}-menu`}
				aria-hidden={!isOpen}
				isOpen={isOpen}
				onKeyDown={handleKeyDown}
				columns={columns}
				numberOfItems={items.length}
			>
				{items.map((item, i) => {
					const row = Math.floor(i / columns);
					const column = Math.abs((row - 1) * columns - i) - columns;
					
					const menuItemJSX = (
						<MenuItem
							backgroundColorOnHover={wrapperBackgroundColors[(row + column) % wrapperBackgroundColors.length]}
							enableHoverEffects={enableMenuItemHoverEffects}
							data-hover={storybookHover}
						>
							<MenuItemIconContainer>
								<MenuItemIcon
									icon={item.icon}
									backgroundColor={iconBackgroundColors[(row + column) % iconBackgroundColors.length]}
								>
									<FontAwesomeIcon icon={item.icon} />
								</MenuItemIcon>
							</MenuItemIconContainer>
							
							<MenuItemTitle
								fontColor={fontColors[(row + column) % fontColors.length]}
								hideArrow={hideArrowOnMenuItemHover ?? !enableMenuItemHoverEffects}
							>
								{item.title}
							</MenuItemTitle>
							{item.description && <MenuItemDescription>{item.description}</MenuItemDescription>}
						</MenuItem>
					);
					
					if ('href' in item) {
						const { title, icon, openOnSamePage, description, onClick, ...everythingElse } = item;
						
						return (
							<MenuItemLink
								key={i}
								target={openOnSamePage ? undefined : '_blank'}
								rel='noopener'
								tabIndex={isOpen ? 0 : -1}
								onClick={(e) => {
									onClick?.(e);
									setActiveDropdown(null);
								}}
								{...everythingElse}
							>
								{menuItemJSX}
							</MenuItemLink>
						);
					}
					
					const { title, icon, description, onClick, ...everythingElse } = item;
					
					return (
						<MenuItemLink
							key={i}
							as={NavLink}
							tabIndex={isOpen ? 0 : -1}
							onClick={(e) => {
								onClick?.(e);
								setActiveDropdown(null);
							}}
							{...everythingElse}
						>
							{menuItemJSX}
						</MenuItemLink>
					);
				})}
			</Menu>
		</Container>
	);
};

export default Dropdown;
