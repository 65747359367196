// Imports
import styled, { css } from 'styled-components/macro';
import BrandLinks from './brand-links';
import lists from './lists';
import List from './list';
import CallToAction from './call-to-action';
import { useMatches } from 'react-router-dom';


// Styled components
const Wrapper = styled.footer`
	width: 100%;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	
	margin-top: 3.75rem;
	gap: 3.75rem;
`;

const BottomPortion = styled.div`
	border-radius: 2.5rem;
	background-color: hsl(206, 60%, 94%);
	
	width: 100%;
	max-width: 68.75rem;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	
	padding: 2.5rem;
	margin: auto;
	
	@media (max-width: 48rem) {
		padding: 1.875rem;
	}
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	row-gap: 1.56rem;
	
	width: 100%;
	
	@media (max-width: 70rem) {
		row-gap: 0;
		flex-direction: column-reverse;
	}
`;

const Links = styled.ul`
	list-style: none;
	display: flex;
	column-gap: 3.125rem;
	margin: 0 auto;
	flex-wrap: wrap;
	row-gap: 1.875rem;
	justify-content: space-between;
`;

const Divider = styled.hr<{ mobileOnly?: boolean }>`
	all: unset;
	width: 83.6%;
	height: 0.0625rem;
	background-color: hsl(206, 70%, 88%);
	
	margin-top: 1.56rem;
	margin-bottom: 0.9375rem;
	
	${({ mobileOnly }) =>
		mobileOnly
			? css`
					display: none;
				`
			: css`
					display: block;
				`}
	
	@media (max-width: 70rem) {
		display: block;
		width: 100%;
		margin: 1.875rem 0;
	}
`;

const Copyright = styled.span`
	color: hsl(206, 69%, 35%);
	text-align: center;
	font-size: 0.75rem;
	line-height: 1;
`;


// Function component
const Footer: React.FC = () => {
	// Use React Router functionality
	const matches = useMatches();
	
	
	// Detect page not found
	const lastMatch = matches[matches.length - 1];
	let hideCTA = false;
	
	if (typeof lastMatch.handle === 'object' && lastMatch.handle) {
		hideCTA = 'hideCTA' in lastMatch.handle;
	}
	
	
	// Return JSX
	return (
		<Wrapper>
			{!hideCTA && <CallToAction />}
			
			<BottomPortion>
				<Container>
					<BrandLinks />
					
					<Divider mobileOnly />
					
					<Links>
						{lists.map((link, i) => (
							<List key={i} {...link} />
						))}
					</Links>
				</Container>
				
				<Divider />
				
				<Copyright>©️ {new Date(Date.now()).getFullYear()} HOA Express, Inc. — All rights reserved</Copyright>
			</BottomPortion>
		</Wrapper>
	);
};

export default Footer;
