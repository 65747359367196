// Imports
import { faBullhorn } from '@fortawesome/pro-regular-svg-icons';
import Feature from '..';


// Banner
const feature: React.ComponentProps<typeof Feature> = {
	banner: {
		label: { icon: faBullhorn, text: 'Blast emails & texts' },
		heading: 'Send community-wide messages rapidly, reliably, & with ease',
		description:
			'HOA Express gives you the power to send blast emails & text messages to all of your residents or just a subset. Our customers trust us to deliver tens of millions of messages every year.',
		screenshot: {
			width: 900,
			height: 701,
			src: '/images/screenshots/features/blast-communications/banner.webp',
		},
	},
	pitch: { text: 'The tools you need to communicate excellently', textMaxWidth: 'min(34.25rem, 45.67vw)' },
	tiles: {
		leftColumn: [
			{
				heading: 'Rich text editing',
				screenshot: {
					width: 1020,
					height: 436,
					src: (layout) => `/images/screenshots/features/blast-communications/${layout}/rich-text.webp`,
				},
			},
			{
				heading: 'Copy past communications',
				screenshot: {
					width: 880,
					height: 226,
					src: (layout) => `/images/screenshots/features/blast-communications/${layout}/copy-communications.webp`,
				},
			},
			{
				heading: 'Choose where to direct replies',
				screenshot: {
					width: 772,
					height: 252,
					src: (layout) => `/images/screenshots/features/blast-communications/${layout}/direct-replies.webp`,
				},
			},
			{
				heading: 'Complete tracking information',
				screenshot: {
					width: 980,
					height: 238,
					src: (layout) => `/images/screenshots/features/blast-communications/${layout}/tracking.webp`,
				},
			},
		],
		rightColumn: [
			{
				heading: 'Attach files',
				screenshot: {
					width: 767,
					height: 203,
					src: (layout) => `/images/screenshots/features/blast-communications/${layout}/attach-files.webp`,
				},
			},
			{
				heading: 'Save drafts for later',
				screenshot: {
					width: 980,
					height: 178,
					src: (layout) => `/images/screenshots/features/blast-communications/${layout}/save-drafts.webp`,
				},
			},
			{
				heading: 'Detailed recipient choices',
				screenshot: {
					width: 980,
					height: 350,
					src: (layout) => `/images/screenshots/features/blast-communications/${layout}/recipient-choices.webp`,
				},
			},
			{
				heading: 'Schedule messages in advance',
				screenshot: {
					width: 726,
					height: 252,
					src: (layout) => `/images/screenshots/features/blast-communications/${layout}/schedule-messages.webp`,
				},
			},
		],
	},
	testimonials: [
		{
			text: 'HOA Express greatly enhances our communication capabilities.',
			source: {
				name: 'Sara',
				hoa: 'Timber Banks HOA',
				url: 'https://www.timberbanks.org/',
			},
		},
		{
			text: 'We love being able to see how many members open the emails we send out. That’s great feedback we’ve never had before.',
			source: {
				name: 'John',
				hoa: 'Fairways of Sherrill Park',
				url: 'https://www.sherrillpark.com/',
			},
		},
	],
};

export default feature;
