// Imports
import { faArrowRightLong } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken } from 'polished';
import { PropsWithChildren } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';


// Styled components
interface StylingProps {
	backgroundColor: string;
	fontColor: string;
	borderColor?: string;
	scale?: number;
}

const Wrapper = styled(Link)<StylingProps>`
	font-size: ${({ scale }) => `calc(1.4rem * ${scale ?? 1})`};
	line-height: 1;
	
	appearance: none;
	
	text-decoration: none;
	
	padding: ${({ scale }) => `calc(0.95rem * ${scale ?? 1}) calc(1.25rem * ${scale ?? 1})`};
	border-radius: 9999rem;
	background: ${({ backgroundColor }) => backgroundColor};
	font-weight: 500;
	display: inline-flex;
	align-items: center;
	gap: ${({ scale }) => `calc(0.5rem * ${scale ?? 1})`};
	border: none;
	
	cursor: pointer;
	
	&& {
		color: ${({ fontColor }) => fontColor};
	}
	
	&:hover,
	&[data-hover] {
		background: ${({ backgroundColor }) => darken(0.05, backgroundColor)};
		
		${({ borderColor, scale }) =>
			borderColor
				? css`
						box-shadow: inset 0 0 0 calc(0.125rem * ${scale ?? 1}) ${darken(0.05, borderColor)};
					`
				: ''}
	}
	
	${({ borderColor, scale }) =>
		borderColor
			? css`
					box-shadow: inset 0 0 0 calc(0.125rem * ${scale ?? 1}) ${borderColor};
				`
			: ''}
`;

const ArrowRight = styled.span<Pick<StylingProps, 'scale'>>`
	font-size: ${({ scale }) => `calc(0.9375rem * ${scale ?? 1})`};
	line-height: 0.8;
`;


// Define the accepted props
interface BaseProps extends StylingProps {
	displayArrowIndicator?: boolean;
	storybookHover?: true;
}

type ButtonProps = BaseProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

type AnchorProps = { href: string } & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'color' | 'href'> & BaseProps;

type LinkProps = PropsWithChildren<{ isNavLink?: boolean } & BaseProps & React.ComponentProps<typeof Link>>;

type CompleteProps = ButtonProps | AnchorProps | LinkProps;


// Function component
const Button: React.FC<CompleteProps> = ({ displayArrowIndicator, scale, storybookHover, children, ...props }) => (
	<Wrapper
		as={'href' in props ? 'a' : 'to' in props ? (props.isNavLink ? NavLink : Link) : 'button'}
		scale={scale}
		data-hover={storybookHover}
		{...props}
	>
		{children}
		{displayArrowIndicator && (
			<ArrowRight scale={scale}>
				<FontAwesomeIcon icon={faArrowRightLong} />
			</ArrowRight>
		)}
	</Wrapper>
);

export default Button;
