// Imports
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import LoadingRow from '../../../../shareables/foundation/front-end/components/tables/loading-row';
import { useAppDispatch } from '../../../../shareables/foundation/front-end/redux/hooks';
import apiActions from '../../../../shareables/integrations/api/redux/actions';
import { GetOnlinePaymentFeesResponse } from '../../../../shareables/types/api/main/countries/online-payment-fees/get';
import Subheading from '../subheading';
import PricingDetailsTable from '../table';
import * as Sentry from '@sentry/browser';
import OnlinePaymentsTable from './table';


// Styled components
const Container = styled.div`
	display: flex;
	flex-direction: column;
	
	padding-top: 0.875rem;
	
	gap: 1.875rem;
`;


// Details for countries we know we support online payments for
const knownCountries: Partial<Record<string, { index: number; customName: string }>> = {
	US: {
		index: 0,
		customName: 'United States',
	},
	CA: {
		index: 1,
		customName: 'Canada',
	},
};


// Define the accepted props
interface Props {
	storybookOnlinePaymentFees?: GetOnlinePaymentFeesResponse;
}


// Function component
const OnlinePayments: React.FC<Props> = ({ storybookOnlinePaymentFees }) => {
	// Use state
	const [onlinePaymentFees, setOnlinePaymentFees] = useState<GetOnlinePaymentFeesResponse | null>(
		storybookOnlinePaymentFees ?? null
	);
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Load online payment fees on mount
	useEffect(() => {
		void dispatch(
			apiActions.call<undefined, undefined, GetOnlinePaymentFeesResponse>({
				action: 'GET',
				uri: '/countries/online-payment-fees',
				completion: (json) => {
					// Build online payment fees while tracking known countries
					const result: GetOnlinePaymentFeesResponse = [];
					
					for (const country of json.response) {
						const knownCountryDetails = knownCountries[country.twoLetterCode];
						
						if (knownCountryDetails) {
							result[knownCountryDetails.index] = { ...country, name: knownCountryDetails.customName };
						} else {
							Sentry.captureMessage(`Unknown online payments country encountered: ${country.twoLetterCode}`, 'error');
							
							result.push(country);
						}
					}
					
					
					// Set state
					setOnlinePaymentFees(result);
				},
			})
		);
	}, [dispatch]);
	
	
	// Return JSX
	return (
		<div>
			<Subheading>Online payments</Subheading>
			
			<p>
				Collect payments from your residents with minimal fees, with the option pass transaction fees on to the
				resident.
			</p>
			
			<Container>
				{onlinePaymentFees
					? onlinePaymentFees.map((country) => <OnlinePaymentsTable key={country.twoLetterCode} country={country} />)
					: [
							<PricingDetailsTable key='US'>
								<thead>
									<tr>
										<th>{knownCountries.US?.customName}</th>
										<th>Fee</th>
									</tr>
								</thead>
								<tbody>
									<LoadingRow />
								</tbody>
							</PricingDetailsTable>,
							<PricingDetailsTable key='CA'>
								<thead>
									<tr>
										<th>{knownCountries.CA?.customName}</th>
										<th>Fee</th>
									</tr>
								</thead>
								<tbody>
									<LoadingRow />
								</tbody>
							</PricingDetailsTable>,
						]}
			</Container>
		</div>
	);
};

export default OnlinePayments;
