// Imports
import { faEarthAmericas } from '@fortawesome/pro-regular-svg-icons';
import Feature from '..';


// Banner
const feature: React.ComponentProps<typeof Feature> = {
	banner: {
		label: { icon: faEarthAmericas, text: 'Domain & email' },
		heading: 'Your community’s home on the world wide web',
		description:
			'HOA Express handles the process of purchasing, configuring, securing, and renewing your domain name. Plus, create fully featured email accounts—great for continuity between leadership changes.',
		screenshot: {
			width: 900,
			height: 449,
			src: '/images/screenshots/features/domain-and-email/banner.webp',
		},
	},
	pitch: { text: 'Create a professional presence on the internet', textMaxWidth: 'min(33.5rem, 44.67vw)' },
	tiles: {
		leftColumn: [
			{
				heading: 'Ready in under an hour (typically)',
				screenshot: {
					width: 940,
					height: 228,
					src: (layout) => `/images/screenshots/features/domain-and-email/${layout}/ready-fast.webp`,
				},
			},
			{
				heading: 'Forwarding for up to 20 recipients',
				screenshot: {
					width: 804,
					height: 426,
					src: (layout) => `/images/screenshots/features/domain-and-email/${layout}/forwarding.webp`,
				},
			},
			{
				heading: 'Sync with all your devices',
				screenshot: {
					width: 520,
					height: 160,
					src: (layout) => `/images/screenshots/features/domain-and-email/${layout}/sync.webp`,
				},
			},
			{
				heading: 'Modern, fully featured webmail',
				screenshot: {
					width: 940,
					height: 540,
					src: (layout) => `/images/screenshots/features/domain-and-email/${layout}/webmail.webp`,
				},
			},
		],
		rightColumn: [
			{
				heading: 'Transfer your existing domain to us',
				screenshot: {
					width: 892,
					height: 410,
					src: (layout) => `/images/screenshots/features/domain-and-email/${layout}/transfer-existing.webp`,
				},
			},
			{
				heading: 'Create up to 10 aliases per account',
				screenshot: {
					width: 980,
					height: 268,
					src: (layout) => `/images/screenshots/features/domain-and-email/${layout}/aliases.webp`,
				},
			},
			{
				heading: 'Protected from accidental deletion',
				screenshot: {
					width: 980,
					height: 176,
					src: (layout) => `/images/screenshots/features/domain-and-email/${layout}/protected-from-deletion.webp`,
				},
			},
			{
				heading: 'Ample storage for every account',
				screenshot: {
					width: 980,
					height: 248,
					src: (layout) => `/images/screenshots/features/domain-and-email/${layout}/ample-storage.webp`,
				},
			},
		],
	},
	testimonials: [
		{
			text: 'HOA Express makes it easy to create a custom domain, and it’s included in the subscription!',
			source: {
				name: 'Kevin',
				hoa: 'Townes at Courthouse Hill',
				url: 'https://www.townesatcourthousehill.com/',
			},
		},
	],
};

export default feature;
