// Imports
import Company from '../types/company';


// Export
const company: Company = {
	name: 'HOA Express',
	socialSlugs: {
		facebook: 'HOAExpress',
		threads: 'hoa.express',
		linkedIn: 'hoa-express',
	},
	productionDomain: 'hoa-express.com',
	supportNumber: '+1 (888) 613-9272',
	bulkEmailSender: true,
	enableOAuth: true,
};

export default company;
