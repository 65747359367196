// Imports
import styled, { css } from 'styled-components/macro';


// Styled components
interface WrapperProps {
	position: 'beginning' | 'end';
}

const aspectRatio = 2 / 1.5;
const width = 3.25;
const horizontalPlacement = width / 2;
const verticalPlacement = width / aspectRatio / 2;

const Wrapper = styled.svg<WrapperProps>`
	width: 3.25rem;
	
	position: absolute;
	
	fill: hsl(96, 54%, 59%);
	stroke-width: 0.5rem;
	stroke: hsl(0, 0%, 100%);
	
	${({ position }) =>
		position === 'beginning'
			? css`
					left: -${horizontalPlacement}rem;
					top: -${verticalPlacement}rem;
				`
			: css`
					right: -${horizontalPlacement}rem;
					bottom: -${verticalPlacement}rem;
				`}
`;


// Function component
const QuotationMark: React.FC<WrapperProps> = ({ position }) => (
	<Wrapper viewBox='0 0 52 44' fill='none' xmlns='http://www.w3.org/2000/svg' position={position} aria-hidden>
		<path
			id='&#226;&#128;&#156;'
			d={
				position === 'beginning'
					? 'M4.88635 35.2898V39.2898H8.88635H16.8409H20.8409V35.2898V28.7557C20.8409 26.7029 21.2611 24.831 22.085 23.0908C23.1015 20.9725 24.2917 18.9384 25.6576 16.987L27.7928 13.9367L24.903 11.5887L20.3576 7.89554L17.8547 5.86193L15.3364 7.87652C13.1077 9.65952 11.2016 11.7627 9.62431 14.1701L9.6243 14.1701L9.61817 14.1795C8.10892 16.4973 6.9336 18.9356 6.10935 21.4907L6.10524 21.5035L6.10123 21.5162C5.31308 24.0165 4.88635 26.4839 4.88635 28.8977V35.2898ZM23.0682 35.2898V39.2898H27.0682H35.0227H39.0227V35.2898V28.7557C39.0227 26.7029 39.443 24.831 40.2669 23.0908C41.2833 20.9725 42.4735 18.9384 43.8394 16.987L45.9747 13.9367L43.0849 11.5887L38.5394 7.89554L36.0365 5.86193L33.5183 7.87652C31.2895 9.65952 29.3834 11.7627 27.8061 14.1701L27.8061 14.1701L27.8 14.1795C26.2907 16.4973 25.1154 18.9356 24.2912 21.4907L24.2871 21.5035L24.283 21.5162C23.4949 24.0165 23.0682 26.4839 23.0682 28.8977V35.2898Z'
					: 'M28.3807 8.75V4.75H24.3807H16.4261H12.4261V8.75V15.2841C12.4261 17.3263 11.9986 19.2078 11.1504 20.9762L11.1466 20.9841L11.1429 20.992C10.1521 23.0814 8.97544 25.1013 7.60942 27.0527L5.47418 30.1031L8.36398 32.451L12.9094 36.1442L15.4123 38.1778L17.9306 36.1632C20.1563 34.3826 22.053 32.2909 23.6103 29.9004C25.141 27.5632 26.3218 25.1196 27.1262 22.5719C27.9381 20.0515 28.3807 17.5672 28.3807 15.142V8.75ZM46.5625 8.75V4.75H42.5625H34.6079H30.6079V8.75V15.2841C30.6079 17.3263 30.1804 19.2078 29.3322 20.9762L29.3285 20.9841L29.3247 20.992C28.3339 23.0814 27.1573 25.1013 25.7912 27.0527L23.656 30.1031L26.5458 32.451L31.0913 36.1442L33.5942 38.1778L36.1124 36.1632C38.3381 34.3827 40.2347 32.291 41.7919 29.9007C43.3228 27.5634 44.5036 25.1197 45.3081 22.5719C46.1199 20.0515 46.5625 17.5672 46.5625 15.142V8.75Z'
			}
			fill='#8BCF5F'
			stroke='white'
			strokeWidth='8'
		/>
	</Wrapper>
);

export default QuotationMark;
