// Imports
import { State as CombinedState, CombinedActions } from '../../../../redux/types';
import { ThunkAction } from 'redux-thunk';


// Define what parameters or files look like
export interface ParametersOrFiles {
	[key: string]: any;
}


// Helper type to prevent inferring
//  -> https://stackoverflow.com/a/56688073/1250940
type NoInfer<T> = [T][T extends any ? 0 : never];


// Helper types for excluding properties
interface ExcludeMe {
	excludeMe: true;
}

type OmitExcludeMeProperties<T> = Pick<T, { [K in keyof T]: NonNullable<T[K]> extends ExcludeMe ? never : K }[keyof T]>;


// Define what an API call looks like
interface APICallBase<Parameters, Files> {
	/** The action portion of the API endpoint (e.g. `GET`, `POST`, etc.). */
	action: string;
	
	
	/** The URI portion of the API endpoint, with a leading forward slash (`/`). The URI can contain values that are automatically replaced with data from the key-value storage system. To use this feature, simply wrap the storage key’s name with curly braces (`{}`), like `/website/{userID}/pages`. */
	uri: string;
	
	
	/** An object with the headers to pass to the API endpoint. */
	headers?: Record<string, string>;
	
	
	/** An object with the parameters to pass to the API endpoint. */
	parameters: undefined extends Parameters ? ExcludeMe : NoInfer<Parameters>;
	
	
	/** An object like parameters, but with values that are `File` objects. These get passed to the API endpoint. */
	files: undefined extends Files ? ExcludeMe : NoInfer<Files>;
	
	
	/** Whether to ignore the API request when it completes, if it was started before the route changed. Defaults to `true`. */
	cancelOnRouteChange?: boolean;
	
	
	/** Whether to ignore the API request when it completes, if it was started before a subsequent request to the same endpoint. Defaults to `false`. */
	cancelOnNewerCall?: boolean;
	
	
	/** An optional function to handle the raw response before it’s parsed as JSON. */
	handleRawResponse?: (rawResponse: string) => void;
	
	
	/** An optional function to call before finishing—whether the API call is successful or not. Useful for calling promise resolvers. */
	callBeforeFinish?: () => void;
	
	
	/** Whether to disable redirecting to the sign in page if authentication is missing or invalid. Will still attempt to refresh the OAuth tokens in a cross-site context, though. */
	disableRedirectToSignIn?: true;
	
	
	/** An optional function that will be called when the API call response requires a reCAPTCHA v2 challenge. */
	recaptchaV2?: () => void;
	
	
	/** For internal use only. */
	initiated?: number;
	
	
	/** For internal use only. */
	disableAuthentication?: boolean;
}

interface APICallWithAutoErrorHandling<Parameters, Files, Response> extends APICallBase<Parameters, Files> {
	/** A function to handle a successful, completed response. The completion handler is passed the parsed JSON received by the API. If `handleErrorsAutomatically` is set to `false`, the completion handler will also receive errors, including errors produced by the front end code itself. Errors will have a non-zero code. Errors produced by the front end itself, and not the API, will contain a negative code. */
	completion: (json: SuccessfulAPIResponse<Response>) => void;
	
	
	/** Whether to automatically display error messages. Defaults to `true`. */
	handleErrorsAutomatically?: true;
}

export interface APICallWithoutAutoErrorHandling<Parameters, Files, Response> extends APICallBase<Parameters, Files> {
	/** A function to handle a successful, completed response. The completion handler is passed the parsed JSON received by the API. If `handleErrorsAutomatically` is set to `false`, the completion handler will also receive errors, including errors produced by the front end code itself. Errors will have a non-zero code. Errors produced by the front end itself, and not the API, will contain a negative code. */
	completion: (json: SuccessfulAPIResponse<Response> | UnsuccessfulAPIResponse) => void;
	
	
	/** Whether to automatically display error messages. Defaults to `true`. */
	handleErrorsAutomatically: false;
}

export type APICall<Parameters, Files, Response> =
	| OmitExcludeMeProperties<APICallWithAutoErrorHandling<Parameters, Files, Response>>
	| OmitExcludeMeProperties<APICallWithoutAutoErrorHandling<Parameters, Files, Response>>;


// Export initial state interface
export interface State {
	endpointsInitiated: {
		[endpoint: string]: number;
	};
}


// Define types/interfaces for actions
export const INITIATE_API_CALL = 'INITIATE_API_CALL';
export interface InitiateAPICallAction {
	type: typeof INITIATE_API_CALL;
	endpoint: string;
	initiated: number;
}


// Export union type
export type Actions = InitiateAPICallAction;


// Export Redux Thunk result type
export type ThunkResult<R> = ThunkAction<R, CombinedState, undefined, CombinedActions>;
