// Imports
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Button from '../button';


// Styled components
const Wrapper = styled.div`
	position: fixed;
	bottom: 1.375rem;
	left: 1.25rem;
	z-index: 9999;
	
	display: flex;
	padding: 0.75rem;
	align-items: center;
	gap: 0.5rem;
	
	border-radius: 0.3125rem;
	border: 0.0625rem solid hsla(205, 55%, 94%, 1);
	background-color: hsl(0 0% 100% / 0.9);
	backdrop-filter: blur(8px);
	
	max-width: 20.8125rem;
	
	@media (max-width: 30rem) {
		max-width: calc(100% - 48px - 3.75rem);
	}
`;

const Explanation = styled.p`
	flex-grow: 1;
	font-size: 0.875rem;
	line-height: 1.2;
	
	> a {
		text-decoration: underline;
		color: inherit;
	}
	
	> a:hover {
		text-decoration: none;
	}
`;

const AccessibilityHeading = styled.h1`
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;


// Function component
const CookieBanner: React.FC = () => {
	// Use state
	const [dismissed, setDismissed] = useState(
		Boolean(document.cookie && document.cookie.split('; ').some((row) => row.startsWith('dismissedCookieBanner')))
	);
	
	
	// Function that sets the `dismissedCookieBanner` cookie, and the corresponding state
	const handleDismissedCookieBanner = () => {
		// Set cookie
		document.cookie = `dismissedCookieBanner=true; Secure; expires=${new Date(
			new Date().setFullYear(new Date().getFullYear() + 1)
		).toUTCString()}`;
		
		
		// Set state
		setDismissed(true);
		
		
		// Transfer focus to main content
		document.querySelector('main')?.focus();
	};
	
	
	// If dismissed or rendering in ReactSnap, display nothing
	if (dismissed || navigator.userAgent === 'ReactSnap') {
		return null;
	}
	
	
	// Return JSX
	return (
		<Wrapper>
			<AccessibilityHeading>Use of cookies</AccessibilityHeading>
			
			<Explanation>
				We use essential cookies for site functionality. By using this site, you agree to our{' '}
				<Link to='/policies/privacy-policy'>privacy policy</Link>.
			</Explanation>
			
			<Button
				type='button'
				onClick={handleDismissedCookieBanner}
				scale={0.8}
				backgroundColor='hsl(0, 0%, 100%)'
				borderColor='hsl(206, 69%, 43%)'
				fontColor='inherit'
			>
				Okay
			</Button>
		</Wrapper>
	);
};

export default CookieBanner;
