// Imports
import React, { useEffect, useState } from 'react';
import appRoutes from './app-routes';
import GlobalStyle from './global-style';
import GlobalErrorBoundary from './components/resources/global-error-boundary';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import RouteChangeListener from './shareables/foundation/front-end/components/resources/route-change-listener';
import { z } from 'zod';
import PageMessages from './shareables/foundation/front-end/components/resources/page-messages';
import Navbar from './components/resources/navbar';
import thirdPartyIdentifiers from './shareables/third-party-identifiers';
import UserFunctionality from './components/resources/user-functionality';
import ScrollBehaviorHandler from './shareables/foundation/front-end/components/resources/scroll-behavior-handler';
import CookieBanner from './components/resources/cookie-banner';
import Title from './components/resources/title';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { useAppDispatch } from './shareables/foundation/front-end/redux/hooks';
import apiActions from './shareables/integrations/api/redux/actions';
import { TrackAdClickParameters } from './shareables/types/api/main/ad-click/track';
import * as Sentry from '@sentry/browser';


// Function component
const App: React.FC = () => {
	// Use state
	const [title, setTitle] = useState(document.title);
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Detect and track ad clicks
	useEffect(() => {
		void (async () => {
			// Extract UTM query parameters from URL
			const searchParams = new URLSearchParams(window.location.search);
			const utmCampaign = searchParams.get('utm_campaign');
			const trackingDetails = [...searchParams.entries()]
				.filter(([name]) => name.startsWith('utm_') && name !== 'utm_campaign')
				.map(([name, value]) => ({ name, value }));
			
			
			// Check for required parameter
			if (utmCampaign) {
				// Generate fingerprint
				let fingerprint: string;
				
				
				// Wrap in a try/catch
				try {
					fingerprint = await getFingerprint();
				} catch (error: unknown) {
					// Report to Sentry
					Sentry.captureException(error);
					
					
					// Return early
					return;
				}
				
				
				// Send ad click information to API
				void dispatch(
					apiActions.call<TrackAdClickParameters>({
						action: 'TRACK',
						uri: `/ad-click`,
						parameters: {
							trackingDetails,
							campaign: utmCampaign,
							fingerprint,
						},
						completion: () => {
							// Do nothing
						},
					})
				);
			}
		})();
	}, [dispatch]);
	
	
	// Boot Intercom, update with user data when authenticated
	useEffect(() => {
		if (window.Intercom) {
			window.Intercom('boot', {
				app_id:
					process.env.REACT_APP__ENVIRONMENT === 'production'
						? thirdPartyIdentifiers.intercomAppID.production
						: thirdPartyIdentifiers.intercomAppID.development,
			});
		}
	}, []);
	
	
	// Build inner content
	const innerContent = (
		<ScrollBehaviorHandler>
			{/* RouteChangeListener must be placed first in the BrowserRouter, so that it processes changes first */}
			<RouteChangeListener title={title} />
			
			<Helmet
				defaultTitle='HOA website builder & software platform // HOA Express'
				titleTemplate='%s // HOA Express'
				onChangeClientState={(newState) => {
					const clientStateSchema = z.object({
						title: z.string(),
					});
					
					const { title: newTitle } = clientStateSchema.parse(newState);
					
					setTitle(newTitle);
				}}
			/>
			
			<Title />
			
			<CookieBanner />
			
			<PageMessages />
			
			<UserFunctionality />
			
			<Navbar />
			
			<Outlet />
		</ScrollBehaviorHandler>
	);
	
	
	// Use React Router functionality
	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route element={<GlobalErrorBoundary>{innerContent}</GlobalErrorBoundary>}>{appRoutes}</Route>
		)
	);
	
	
	// Return JSX
	return (
		<React.StrictMode>
			<GlobalErrorBoundary>
				<GlobalStyle />
				
				<HelmetProvider>
					<RouterProvider router={router} />
				</HelmetProvider>
			</GlobalErrorBoundary>
		</React.StrictMode>
	);
};

export default App;
