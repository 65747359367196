// Imports
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './app';
import store from './shareables/foundation/front-end/redux/store';
import setUp from './shareables/foundation/front-end/utils/set-up';


// Set up
setUp();


// Disable Plausible if not in production
if (process.env.REACT_APP__ENVIRONMENT !== 'production') {
	localStorage.setItem('plausible_ignore', 'true');
}


// Mount and render
const container = document.getElementById('root');

if (!container) {
	throw new Error('Root container should exist');
}

if (container.hasChildNodes()) {
	ReactDOM.hydrate(
		<Provider store={store}>
			<App />
		</Provider>,
		container
	);
} else {
	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		container
	);
}
