// Imports
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Home from '../home';


// Function component
const CondoAssociationWebsites: React.FC = () => {
	// Return JSX
	return (
		<React.Fragment>
			<Helmet>
				<meta name='robots' content='noindex, nofollow' />
			</Helmet>
			
			<Home />
		</React.Fragment>
	);
};

export default CondoAssociationWebsites;
