// Imports
import { useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import Screenshot from './screenshot';


// Styled components
const gap = '1.25rem';

const Wrapper = styled.div`
	display: flex;
	gap: ${gap};
	width: 100%;
	max-width: 68.75rem;
	
	padding-top: 1.875rem;
	
	@media (max-width: 48rem) {
		display: grid;
		grid-auto-flow: row;
	}
`;

const DesktopColumn = styled.div`
	display: flex;
	gap: ${gap};
	flex-direction: column;
	flex: 1 1 0;
	
	@media (max-width: 48rem) {
		display: none;
	}
`;

interface Color {
	background: string;
	text: string;
}

const Tile = styled.figure<Color & { hideOnDesktop?: boolean }>`
	width: 100%;
	padding: 1.25rem 0;
	
	background-color: ${({ background }) => background};
	color: ${({ text }) => text};
	
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.9375rem;
	
	border-radius: 0.625rem;
	
	> figcaption {
		font-size: 1.5rem;
		font-weight: 600;
		line-height: 1;
		padding: 0 1.25rem;
		text-align: center;
	}
	
	${({ hideOnDesktop }) =>
		hideOnDesktop
			? css`
					display: none;
					
					@media (max-width: 48rem) {
						display: flex;
					}
				`
			: ''}
`;


// Define the accepted props
interface Tile {
	heading: string;
	screenshot: {
		width: number;
		height: number;
		src: (layout: 'two-column' | 'one-column') => string;
	};
}

interface Props {
	leftColumn: Tile[];
	rightColumn: Tile[];
}


// Function component
const Tiles: React.FC<Props> = ({ leftColumn, rightColumn }) => {
	// Build tile colors
	const leftColumnColors: Color[] = [
		{ background: 'hsl(36 100% 90%)', text: 'hsl(36 44% 52%)' },
		{ background: 'hsl(96 100% 90%)', text: 'hsl(96 58% 35%)' },
		{ background: 'hsl(206 100% 90%)', text: 'hsl(206 69% 35%)' },
	];
	
	const rightColumnColors = leftColumnColors.slice(1);
	
	rightColumnColors.push(leftColumnColors[0]);
	
	
	// Build single column tile order
	const singleColumnTiles = useMemo(() => {
		const arr: Tile[] = [];
		let i = 0;
		
		while (leftColumn[i] || rightColumn[i]) {
			if (leftColumn[i]) {
				arr.push(leftColumn[i]);
			}
			
			if (rightColumn[i]) {
				arr.push(rightColumn[i]);
			}
			
			i += 1;
		}
		
		return arr;
	}, [leftColumn, rightColumn]);
	
	
	// Helper function that creates tiles
	const createTiles = (
		tiles: Tile[],
		colors: Color[],
		options: { layout: 'two-column' | 'one-column'; hideOnDesktop?: boolean }
	) =>
		tiles.map((tile, i) => (
			<Tile key={i} {...colors[i % colors.length]} hideOnDesktop={options.hideOnDesktop}>
				<figcaption>{tile.heading}</figcaption>
				
				<Screenshot
					alt={`Example of “${tile.heading}”`}
					src={tile.screenshot.src(options.layout)}
					width={tile.screenshot.width}
					height={tile.screenshot.height}
				/>
			</Tile>
		));
	
	
	// Return JSX
	return (
		<Wrapper>
			<DesktopColumn>{createTiles(leftColumn, leftColumnColors, { layout: 'two-column' })}</DesktopColumn>
			
			<DesktopColumn>{createTiles(rightColumn, rightColumnColors, { layout: 'two-column' })}</DesktopColumn>
			
			{createTiles(singleColumnTiles, leftColumnColors, { layout: 'one-column', hideOnDesktop: true })}
		</Wrapper>
	);
};

export default Tiles;
