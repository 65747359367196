// Imports
import { useMatches } from 'react-router-dom';
import styled from 'styled-components/macro';
import Button from '../../../resources/button';
import Houses from './houses';


// Styled components
const Wrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 2.1875rem;
	
	position: relative;
`;

const HeadingContainer = styled.div`
	text-align: center;
	
	> span {
		color: hsl(206, 69%, 43%);
		font-size: 1.875rem;
		text-transform: uppercase;
		line-height: 1;
		font-weight: 400;
		
		@media (max-width: 48rem) {
			line-height: 1.2;
		}
	}
`;

const headingStyle = `
	text-align: center;
	font-size: 5rem;
	color: hsl(206, 69%, 35%);
	font-family: Zilla Slab;
	font-weight: 600;
	line-height: 1;
	
	@media (max-width: 48rem) {
		font-size: 3.75rem;
	}
`;

const HomepageHeading = styled.h1`
	${headingStyle}
`;

const CondoAssociationHeading = styled.h1`
	${headingStyle}
	
	@media (min-width:  48rem) {
		margin: 0.25rem 0;
		line-height: 0.9;
	}
`;

const Features = styled.p`
	color: hsl(206, 25%, 20%);
	font-size: 1.5rem;
	line-height: 1.5;
	text-align: center;
	max-width: 37.5rem;
	
	@media (max-width: 48rem) {
		font-size: 1.125rem;
		max-width: 21.5625rem;
	}
`;

const CallToAction = styled.div`
	display: grid;
	justify-items: center;
	gap: 0.5rem;
	
	> a {
		width: fit-content;
	}
`;

const FreeTrial = styled.small`
	color: hsl(206, 25%, 20%);
	font-size: 0.75rem;
`;


// Function component
const Headline: React.FC = () => {
	// Use React Router functionality
	const matches = useMatches();
	
	
	// Detect if route is a condo association route
	const isCondoRoute = matches.some((match) => match.pathname === '/condo-association-websites');
	
	
	// Return JSX
	return (
		<Wrapper>
			<Houses />
			
			<HeadingContainer>
				<span>The delightfully easy & powerful</span>
				{isCondoRoute ? (
					<CondoAssociationHeading>
						Condo association
						<br />
						website builder
					</CondoAssociationHeading>
				) : (
					<HomepageHeading>HOA website builder</HomepageHeading>
				)}
			</HeadingContainer>
			
			<Features>
				Publish information, store resident details, send blast emails & texts, and promote involvement—all from your
				community’s beautiful online home
			</Features>
			
			<CallToAction>
				<Button
					href={`https://start.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}`}
					rel='noopener'
					backgroundColor='hsl(96, 54%, 59%)'
					fontColor='#fff'
					displayArrowIndicator
				>
					Start for free
				</Button>
				
				<FreeTrial>30-day free trial. No credit card needed.</FreeTrial>
			</CallToAction>
			
			<Houses mirrored />
		</Wrapper>
	);
};

export default Headline;
