// Imports
import marked from 'marked';


/** Parse a string as Markdown into HTML, this HTML is not sanitized */
export default function parseMarkdown(markdown: string): string {
	// Transfer the value
	let content = markdown;
	
	
	// Prevent HTML-encoded characters from being rendered
	//  -> Converts the & in an HTML-encoded character to &amp;
	content = content.replace(/&(.{1,8}?;)/g, '&amp;$1');
	
	
	// Prevent HTML from being written
	//  -> Replaces all < characters to &lt; including escaped characters
	//  -> The first replacement replaces escaped < characters, which the Markdown editor should always do
	//  -> The second replacement replaces unescaped < characters, which should only be possible if a user tries to interact with the endpoint directly (likely with bad intent)
	content = content.replace(/\\</g, '&lt;');
	content = content.replace(/</g, '&lt;');
	
	
	// Encode quotes
	content = content.replace(/'/g, '&#39;');
	content = content.replace(/"/g, '&quot;');
	
	
	// Inject buttons
	content = content.replace(
		/\[\\\[\\\[(.+?)\\\]\\\]\]\((.+?)\)/g,
		'<a href="$2" class="btn-primary" style="margin: 10px 0;">$1</a>'
	);
	
	
	// Create a custom renderer to override the default table renderer
	//  -> This allows styling in `<EditorGeneratedContentContainer />` to apply to Markdown tables
	const renderer = new marked.Renderer();
	renderer.table = (header, body) => `<div class="table"><table>${header}${body}</table></div>`;
	
	
	// Parse Markdown
	content = marked(content, {
		gfm: true,
		breaks: true,
		renderer: renderer,
	});
	
	
	// Return
	return content.trim();
}
