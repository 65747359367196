// Imports
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import pull from 'lodash/pull';
import pullAllWith from 'lodash/pullAllWith';
import isObject from 'lodash/isObject';
import forOwn from 'lodash/forOwn';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import omitBy from 'lodash/omitBy';
import mapValues from 'lodash/mapValues';
import assign from 'lodash/assign';
import isNil from 'lodash/isNil';


// Turn an element into an accessible button with both click and keyboard events (https://stackoverflow.com/a/50731217/1250940)
interface ButtonizeOptions {
	onKeyDown?: (event: React.KeyboardEvent) => void;
}


// Type guard that checks for an object of unknown shape
const isUnknownObject = (object: unknown): object is { [key: string]: unknown } =>
	typeof object === 'object' && !Array.isArray(object);


/** Recursively iterates through an object and removes all empty strings, from both objects and arrays. */
function pruneEmptyStrings(object: unknown[]): unknown[];
function pruneEmptyStrings(object: { [key: string]: unknown }): { [key: string]: unknown };
function pruneEmptyStrings(object: { [key: string]: unknown } | unknown[]): { [key: string]: unknown } | unknown[] {
	return (function prune(current) {
		if (Array.isArray(current)) {
			const prunedArray = pull<unknown>(current, undefined, null, '');
			
			for (const element of prunedArray) {
				if (typeof element === 'object' || Array.isArray(element)) {
					prune(element as { [key: string]: unknown } | unknown[]);
				}
			}
			
			return prunedArray;
		}
		
		forOwn(current, function (value: unknown, key: string) {
			if (isString(value) && isEmpty(value)) {
				delete current[key];
			} else if (isUnknownObject(value) || Array.isArray(value)) {
				prune(value);
			}
		});
		
		return current;
	})(cloneDeep(object));
}


/** Recursively iterates through an object and removes any empty objects or arrays that it contains. */
function pruneEmptyObjectsAndArrays(collection: unknown[]): unknown[];
function pruneEmptyObjectsAndArrays(collection: { [key: string]: unknown }): { [key: string]: unknown };
function pruneEmptyObjectsAndArrays(
	collection: { [key: string]: unknown } | unknown[]
): { [key: string]: unknown } | unknown[] {
	if (collection instanceof window.File) {
		return collection;
	}
	
	if (Array.isArray(collection)) {
		let prunedCollection = collection.map<unknown>((element: unknown) => {
			// Redundant conditions to satisfy Typescript
			if (Array.isArray(element)) {
				return pruneEmptyObjectsAndArrays(element);
			}
			
			if (isUnknownObject(element)) {
				return pruneEmptyObjectsAndArrays(element);
			}
			
			
			// Return
			return element;
		});
		
		prunedCollection = pullAllWith(prunedCollection, [undefined, null, '', [], {}], isEqual);
		
		return prunedCollection;
	} else if (isObject(collection)) {
		let prunedCollection = mapValues(collection, pruneEmptyObjectsAndArrays);
		prunedCollection = omitBy(prunedCollection, isEmpty);
		prunedCollection = assign(prunedCollection, omitBy(collection, isObject));
		prunedCollection = omitBy(prunedCollection, isNil);
		prunedCollection = omitBy(prunedCollection, (value: any) => value === '');
		prunedCollection = assign(
			prunedCollection,
			omitBy(collection, (value: any) => !(value instanceof window.File || value instanceof window.FileList))
		);
		
		return prunedCollection;
	}
	
	return collection;
}


// Exports
const helperFunctions = {
	pruneEmptyStrings,
	
	pruneEmptyObjectsAndArrays,
	
	
	/** Recursively iterates over all React children, and calls the provided handler function for each. The handler function is passed a single argument: the React child. */
	recursivelyIterate(
		children: React.ReactNode | undefined | null,
		handler: (child: React.ReactElement) => React.ReactElement
	): React.ReactNode {
		return React.Children.map(children, (child) => {
			if (!React.isValidElement(child)) {
				return child;
			}
			
			const { children } = child.props as Record<string, unknown>;
			
			if (children) {
				return handler(
					React.cloneElement(child, undefined, helperFunctions.recursivelyIterate(children as React.ReactNode, handler))
				);
			}
			
			return handler(child);
		});
	},
	
	
	/** Turns an element into an accessible button that can be accessed with both the mouse and keyboard. Options are optional, but you can provide a function as the `onKeyDown` sub-key inside of it to handle key down events for `<enter>` and `<space>`. Doing so will prevent those events from propagating. */
	buttonize(handler: (event: React.MouseEvent | React.KeyboardEvent) => void, options?: ButtonizeOptions) {
		options = options || {};
		
		return {
			role: 'button',
			tabIndex: 0,
			onMouseDown: (event: React.MouseEvent) => {
				event.preventDefault();
			},
			onClick: handler,
			onKeyDown:
				typeof options.onKeyDown === 'function'
					? options.onKeyDown
					: (event: React.KeyboardEvent) => {
							if (event.keyCode === 13 || event.keyCode === 32) {
								event.stopPropagation();
								event.preventDefault();
								handler(event);
							}
						},
		};
	},
	
	
	/** Returns the provided string, but with the first character capitalized. Remaining characters are untouched. */
	ucfirst(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	},
	
	
	/** Simplify a string for searching by removing diacritics and most special characters, and making other minor standardizations */
	simplifyStringForSearching(text: string) {
		if (typeof text !== 'string') {
			return '';
		}
		
		return text
			.toLowerCase()
			.replace(/\s+/g, ' ') // Converts multiple whitespace characters to a single space
			.trim() // Removes leading/trailing whitespace
			.normalize('NFD') // Decompose diacritic characters
			.replace(/[\u0300-\u036f]/g, '') // Remove diacritics
			.replace(/[^a-z0-9 ]/g, ''); // Removes special characters
	},
};

export default helperFunctions;
