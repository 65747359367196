// Imports
import React, { lazy, Suspense } from 'react';
import { faArrowsLeftRight, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components/macro';
import { View } from '..';
import subscriptionAmountMapping from '../../../../shareables/utils/billing/subscription-amount-mapping';
import subscriptionSizes from '../../../../shareables/utils/billing/subscription-sizes';
import planMapping from '../../../../shareables/utils/billing/plan-mapping';


// Lazily-loaded components
const ReactSlider = lazy(() => import('react-slider'));


// Styled components
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.3125rem;
	width: 100%;
	
	.slider {
		height: 1.75rem;
		width: 11.25rem;
		min-width: 11.25rem;
		
		@media (max-width: 23rem) {
			width: 100%;
		}
		
		> .thumb {
			:focus-visible {
				outline: 0;
				
				svg:first-child {
					outline: -webkit-focus-ring-color auto 1px;
				}
			}
		}
	}
	
	@media (max-width: 23rem) {
		gap: 0.75rem;
	}
`;

const PriceContainer = styled.div<{ view: View }>`
	display: flex;
	align-items: center;
	gap: 0.9375rem;
	
	line-height: 1.2;
	
	${({ view }) =>
		view === 'YEARLY'
			? css`
					@media (max-width: 23rem) {
						flex-direction: column;
						width: 100%;
						align-items: start;
						
						gap: 0.3125rem;
					}
				`
			: ''}
`;

const Price = styled.span`
	display: flex;
	align-items: flex-start;
	
	font-size: 3.75rem;
	font-weight: 600;
	line-height: 1;
	
	@media (max-width: 30rem) {
		font-size: 3rem;
	}
`;

const DollarSign = styled.span`
	font-size: 1.875rem;
	font-weight: 600;
	line-height: 1;
	
	@media (max-width: 30rem) {
		font-size: 1.5rem;
	}
`;

const SliderContainer = styled.div`
	display: flex;
	gap: 1.125rem;
	align-items: center;
	
	@media (max-width: 23rem) {
		flex-direction: column;
		align-items: flex-start;
		
		gap: 0.3125rem;
		
		width: 100%;
	}
`;

const IconLayers = styled.span`
	font-size: 1.75rem;
	cursor: pointer;
	left: -0.21875rem;
	position: absolute;
	
	--fa-border-color: hsl(205, 69%, 87%);
	--fa-border-radius: 50%;
	--fa-border-width: 0.125rem;
	--fa-border-padding: 0;
`;

const ThumbContainer = styled.div`
	position: relative;
	width: 1.75rem;
	height: 1.75rem;
`;

const renderThumb: React.ComponentProps<typeof ReactSlider>['renderThumb'] = (props) => (
	<div {...props}>
		<ThumbContainer>
			<IconLayers className='fa-layers fa-fw'>
				<FontAwesomeIcon icon={faCircle} color='hsl(206, 69%, 35%)' border />
				<FontAwesomeIcon icon={faArrowsLeftRight} color='hsl(0, 0%, 100%)' transform='shrink-5.71' />
			</IconLayers>
		</ThumbContainer>
	</div>
);

const SliderTrack = styled.div<{ index: number }>`
	height: 0.125rem;
	background-color: hsl(206, 45%, 64%);
	
	position: absolute;
	top: calc(50% - 0.0625rem);
`;

const renderTrack: React.ComponentProps<typeof ReactSlider>['renderTrack'] = (props, state) => (
	<SliderTrack
		{...(props as unknown as Omit<
			React.DetailedHTMLProps<React.HTMLProps<HTMLDivElement>, HTMLDivElement>,
			'ref' | 'as'
		>)}
		index={state.index}
	/>
);


// Define the accepted props
interface Props {
	view: View;
	sliderIndex: number;
	setSliderIndex: React.Dispatch<React.SetStateAction<number>>;
}


// Function component
const DeluxePlanPricing: React.FC<Props> = ({ view, sliderIndex, setSliderIndex }) => (
	<Wrapper>
		<PriceContainer view={view}>
			<Price>
				<DollarSign>$</DollarSign>
				{view === 'YEARLY'
					? (subscriptionAmountMapping[planMapping.deluxe][subscriptionSizes[sliderIndex]] * 0.9).toFixed(2)
					: subscriptionAmountMapping[planMapping.deluxe][subscriptionSizes[sliderIndex]]}
			</Price>
			<span>
				Per month
				{view === 'YEARLY' && (
					<React.Fragment>
						,<br />
						billed yearly
					</React.Fragment>
				)}
			</span>
		</PriceContainer>
		
		<SliderContainer>
			<Suspense fallback={<div className='slider' />}>
				<ReactSlider
					value={sliderIndex}
					min={0}
					max={subscriptionSizes.length - 1}
					onChange={(newValue) => {
						if (!(typeof newValue === 'number')) {
							throw new Error('This should not be possible');
						}
						
						setSliderIndex(newValue);
					}}
					renderThumb={renderThumb}
					renderTrack={renderTrack}
					ariaValuetext={`${subscriptionSizes[sliderIndex].toLocaleString()} households`}
					ariaLabel='Community size'
				/>
			</Suspense>
			
			<span>{subscriptionSizes[sliderIndex].toLocaleString()} households</span>
		</SliderContainer>
	</Wrapper>
);

export default DeluxePlanPricing;
